/* global require, up, Cleave, FilePond, DataTable, tail, bb */

/*
  toggles checkboxes .checked state
 */
up.compiler("[toggle]", function(element, data){
  const cleanups = [
    up.on(element, "click", function(e){
      console.log("event", e)
      const prevent_toggle = up.element.closest(e.target, "[no-toggle]")
      const checkbox = element.querySelector("[type=checkbox]")
      if (e.target != checkbox && !prevent_toggle) {
        checkbox.checked = !checkbox.checked
      }
    })
  ]

  return function() {
    cleanups.forEach((cleanup_fn) => cleanup_fn())
  }
})
