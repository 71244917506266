/* global require, up, Intl */

var USA_CURRENCY_FORMAT =         new Intl.NumberFormat("en-US", { style: "currency", currency: "USD"});
var USA_CURRENCY_FORMAT_COMPACT = new Intl.NumberFormat("en-US", { style: "currency",
                                                                   currency: "USD",
                                                                   compactDisplay: "short",
                                                                   notation: "compact"});
// display for currency formatting
// ref: https://tc39.es/ecma402/#sec-properties-of-intl-numberformat-constructor
// MDN is dogshit for docs for this function/namespace
up.compiler("currency-compact", {batch: true}, function(elements, data) {
  up.util.task (function (){
    elements.forEach(element => {
      if (up.util.isNumber(data)) {
        const money = data;
        const formatted_money = USA_CURRENCY_FORMAT_COMPACT.format(money)
        element.innerHTML = formatted_money;
      }
    })
  })
})

up.compiler("currency", {batch: true}, function(elements, data) {
  up.util.task (function (){
    elements.forEach(element =>{
      const number_to_format = up.util.isNumber(data) ? data : Number.parseFloat(element.innerText);

      if (!Number.isNaN(number_to_format)) {
        const formatted_number = USA_CURRENCY_FORMAT.format(number_to_format).slice(0, -3);
        element.innerHTML = formatted_number;
      }
    })
  })
})

up.compiler("filesize", {batch: true}, function(elements, data) {
  up.util.task (function (){
    elements.forEach(element =>{
      const number_to_format = up.util.isNumber(data) ? data : Number.parseFloat(element.innerText);

      if (!Number.isNaN(number_to_format)) {
        var i = Math.floor(Math.log(number_to_format) / Math.log(1024));
        const file_size = (number_to_format / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["B", "kB", "MB", "GB", "TB"][i];
        element.innerHTML = file_size;
      }
    })
  })
})

const formatPercentage = (value, locale = "en-US") => {
  return Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 2
  }).format(value);
};

const formatPercentageCoarse = (value, locale = "en-US") => {
  return Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value);
};

up.compiler("percentage", function(element, data) {
  const number_to_format = up.util.isNumber(data) ? data : Number.parseFloat(element.innerText);
  if (!Number.isNaN(number_to_format)) {
    const formatted_number = formatPercentage(number_to_format/100)
    element.innerHTML = formatted_number;
  }
  return function(){ /* no-op */ }
})

up.compiler("percentage[coarse]", function(element, data) {
  const number_to_format = up.util.isNumber(data) ? data : Number.parseFloat(element.innerText);
  if (!Number.isNaN(number_to_format)) {
    const formatted_number = formatPercentageCoarse(number_to_format/100)
    element.innerHTML = formatted_number;
  }
  return function(){ /* no-op */ }
})


// make compiler for time formatting (months years...)
// Intl.RelativeTimeFormat
// 2. If unit is "seconds", return "second".
//   3. If unit is "minutes", return "minute".
//   4. If unit is "hours", return "hour".
//   5. If unit is "days", return "day".
//   6. If unit is "weeks", return "week".
//   7. If unit is "months", return "month".
//   8. If unit is "quarters", return "quarter".
//   9. If unit is "years", return "year".


// up.compiler("format-number", function(element, data) {
//   style = element.setData;
//   unit = element?.attributes?.unit?.value;
//   prefix =
//     suffix =

//   if (up.util.isNumber(data)) {
//     let number = data;
//     let formatted_number = formatPercentage(number/100)
//     element.innerHTML = formatted_number;
//   }
//   return function(){ /* no-op */ }
// })
