/* global require */

// require("./compilers/auto")
// require("./compilers/carousel")
// require("./compilers/cleave")
// require("./compilers/croppie")
require("./compilers/copy_to_clipboard")
require("./compilers/data_tables")
require("./compilers/donut_chart")
require("./compilers/easy_password")
require("./compilers/emit")
require("./compilers/filepond")
require("./compilers/formatting")
require("./compilers/forms/checkbox")
require("./compilers/forms/input")
require("./compilers/forms/remove_button")
require("./compilers/otp")
require("./compilers/plyr")
require("./compilers/pretty_print")
require("./compilers/progress_bar")
require("./compilers/semantic_accordion")
require("./compilers/semantic_dropdown")
require("./compilers/semantic_tabs")
require("./compilers/slide_show")
require("./compilers/switch")
require("./compilers/tabs")
require("./compilers/tail_select")
require("./compilers/tippy")
require("./compilers/toggle")
require("./lib/unpoly")
require("./lib/utils")
