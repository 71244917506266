// prevent user pressing return key from sending a form submit
up.compiler("[no-submit]", function(element, data){
  // const form = up.element.closest(element, "form")
  const stop_keydown = up.on(element, "keydown", function(event){
    console.log (event)
    if ("Enter" == event.code){
      up.event.halt(event)
    }
  })

  const stop_keyup = up.on(element, "keydown", function(event){
    if ("Enter" == event.code){
      up.event.halt(event)
    }
  })

  return function (){
    stop_keydown()
    stop_keyup()
  }
})
