/* global require, up */

/*
  listen to events and automatically render the contents of the events to the given `targets`

  [listen-for]: event name that component reacts to
  [listen-to]:  DOM element that is being listened to (will be `document` if element isn't found)

  updates ".bar" DOM node `.style.width` based on event data:
  event.count
  event.idx

  example:
  // <progress-bar
  //   listen-for="carousel:change-slide"
  //   listen-to="carousel-container"
  //   class="ui tiny progress">
  //     <div class="bar"></div>
  // </progress-bar>
*/

up.compiler("progress-bar", function(element, data){
  const listen_to  = up.element.attr(element, "listen-to")
  const listen_for = up.element.attr(element, "listen-for")
  const bar        = element.querySelector(".bar");

  if (!listen_for) {
    console.error("there is no event for the progress-bar to listen for")
    return
  }

  if (!listen_to) {
    console.error("there is no specifiec emitter for the progress-bar to listen to")
    return
  }

  function render_progress(event){
    // console.log(event)
    const count            = event.count
    const idx              = event.idx
    const percent_complete = (idx/count) * 100;
    bar.style.width        = `${parseFloat(percent_complete).toFixed(2)}%`;
  }

  if(listen_to){
    const emitter = element.closest(listen_to)
    //console.log("progress-bar: listening to", emitter, "for", listen_for, "events")
    return up.on(emitter, listen_for, render_progress)
  }
  else {
    return up.on(listen_for, render_progress)
  }
})
