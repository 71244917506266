// docs:
// https://datatables.net/examples/non_jquery/init.html
// https://datatables.net/reference/option/

// instance obj docs: https://datatables.net/reference/api/

/*

  # DataTables - Features
  these seem like they are booleans
  +-------------+---------------------------------------------------------------------------------------------------------+
  |Name         |Summary                                                                                                  |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |autoWidth    |Feature control DataTables" smart column width handling                                                  |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |deferRender  |Feature control deferred rendering for additional speed of initialisation.                               |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |info         |Feature control table information display field                                                          |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |lengthChange |Feature control the end user"s ability to change the paging display length of the table.                 |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |ordering     |Feature control ordering (sorting) abilities in DataTables.                                              |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |paging       |Enable or disable table pagination.                                                                      |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |processing   |Feature control the processing indicator.                                                                |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |scrollX      |Horizontal scrolling                                                                                     |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |scrollY      |Vertical scrolling                                                                                       |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |searching    |Feature control search (filtering) abilities                                                             |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |serverSide   |Feature control DataTables" server-side processing mode.                                                 |
  +-------------+---------------------------------------------------------------------------------------------------------+
  |stateSave    |State saving - restore table state on page reload                                                        |
  +-------------+---------------------------------------------------------------------------------------------------------+


  # DataTables - Options https://datatables.net/reference/option/

  +---------------------------+----------------------------------------------------------------------------------------------+
  |Option Name                |Summary                                                                                       |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |deferLoading               |Delay the loading of server-side data until second draw                                       |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |destroy                    |Destroy any existing table matching the selector and replace with the new options.            |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |displayStart               |Initial paging start point                                                                    |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |dom                        |Define the table control elements to appear on the page and in what order                     |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |lengthMenu                 |Change the options in the page length select list.                                            |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |order                      |Initial order (sort) to apply to the table                                                    |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |orderCellsTop              |Control which cell the order event handler will be applied to in a column                     |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |orderClasses               |Highlight the columns being ordered in the table"s body                                       |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |orderFixed                 |Ordering to always be applied to the table                                                    |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |orderMulti                 |Multiple column ordering ability control.                                                     |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |pageLength                 |Change the initial page length (number of rows per page)                                      |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |pagingType                 |Pagination button display options                                                             |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |renderer                   |Display component renderer types                                                              |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |retrieve                   |Retrieve an existing DataTables instance                                                      |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |rowId                      |Data property name that DataTables will use to set tr element DOM IDs                         |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |scrollCollapse             |Allow the table to reduce in height when a limited number of rows are shown.                  |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |search                     |Set an initial filter in DataTables and / or filtering options.                               |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |search.caseInsensitive     |Control case-sensitive filtering option.                                                      |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |search.regex               |Enable / disable escaping of regular expression characters in the search term.                |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |search.return              |Enable / disable DataTables" search on return                                                 |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |search.search              |Set an initial filtering condition on the table.                                              |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |search.smart               |Enable / disable DataTables" smart filtering                                                  |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |searchCols                 |Define an initial search for individual columns.                                              |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |searchDelay                |Set a throttle frequency for searching                                                        |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |stateDuration              |Saved state validity duration                                                                 |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |stripeClasses              |Set the zebra stripe class names for the rows in the table.                                   |
  +---------------------------+----------------------------------------------------------------------------------------------+
  |tabIndex                   |Tab index control for keyboard navigation                                                     |
  +---------------------------+----------------------------------------------------------------------------------------------+

*/

up.compiler("body[backoffice] table[smart]", function(element, data){
  try {
    let options = {
      pageLength: -1,
      lengthMenu: [ [25, 50, 100, -1], [25, 50, 100, "All"] ],
      //order: true
      //stateSave: true, // this doesn"t seem to work very well with unpoly
    };

    if (data){
      options = up.util.merge(options, data)
    }

    let data_table = new DataTable(element, options);
    return function(){
      //element.remove(); // i have no idea if this is correct
      data_table.destroy(); // this isn"t a function?
    }
  }
  catch (e){
    console.error ("data tables couldn't init on", element, data)
  }
})
