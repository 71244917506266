/* global require, up */
up.compiler("[copy-to-clipboard-address]", function(element, data){
  return up.on(element,"click", function(){
    const previous_inner_html = element.innerHTML;
    const url = window.location.href; 
    navigator.clipboard.writeText(url).then(function() {
      element.innerText = "Link Copied!"
      element.classList.add("copying")
      up.util.timer(2000,function(){
        element.innerHTML = previous_inner_html
        element.classList.remove("copying")
      }) 
    }).catch(function(err) {
      console.error('Could not copy text: ', err); 
    });
    
  })
})
up.compiler("[copy-to-clipboard]", function(element, data){
  const copy_text = up.element.attr(element, "copy-to-clipboard");
  return up.on(element,"click", function(){
    if (copy_text){
      const previous_inner_html = element.innerHTML;
      navigator.clipboard.writeText(copy_text)
      element.innerText = "Copied!"
      element.classList.add("grey")
      up.util.timer(2000,function(){
        element.innerHTML = previous_inner_html
        element.classList.remove("grey")
      })
    }
  })
})

