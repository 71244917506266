/* global require, up */

// call form.submit on [remove-button] click, after removing the element from the DOM
up.compiler("[remove-button]", function(element, data){
  const form = up.element.closest(element, "form")
  return up.on(element, "click", function(){
    let el_to_remove = up.element.closest(element, "[removable]")
    if(el_to_remove){
      up.element.remove(el_to_remove);
      up.submit(form)
    }
  })
})

up.on('item:remove', function(event){
  //console.log('item:remove',  event.response, event)
  let remove_target
  if (event.id) {
     remove_target = document.getElementById(event.id)
  }
  else if (event.selector) {
    remove_target =  up.element.closest(event.target, event.selector)
  }
  else
  {
    console.error("item:remove", "no up-emit-props 'id' or 'selector' given")
  }

  if (! remove_target) {
    console.error('item:remove', "could not find element to remove based on ID", event.id)
    console.error('item:remove', "could not find element to remove based on selector", event.selector)
  }

  const remove_event = event
  const loaded_handler = up.on('up:request:loaded', function(event){
    //console.log('up:request:loaded',  event.response, event)
    if(200 == event.response.status){
      up.element.remove(remove_target);
      event.preventDefault()
      loaded_handler()
    }
  })
})
