/* global require, up, Cleave, FilePond, DataTable, tail, bb */

const {djb2Hash} = require("../lib/utils")

// similar to up-document
up.compiler("[up-template]", function (element, data){
  const target                  = up.element.attr(element, "up-target")
  const template_id             = up.element.attr(element, "up-template")
  const href                    = up.element.attr(element, "href")
  const default_template_lookup = up.fragment.closest(element, "template")

  function find_template_content(template_id){
    return template_id ? up.fragment.closest(element, `${template_id}`) : default_template_lookup ?? default_template_lookup
  }

  return up.on(element, "up:click", function (event){
    //console.log("up-template:up:click", event)
    const template            = find_template_content(template_id)
    const template_source     = up.element.attr(template, "up-source") ?? ""
    const template_transition = up.element.attr(template, "up-transition") ?? "cross-fade"
    const template_clone      = template.content.cloneNode(true)
    // source: is very important, without it we will get something
    // like `event.baseURI/true` as the up-source, which is probably
    // going to screw up most form submits.
    const default_render_opts = {
      origin:     element,
      target:     target,
      document:   template_clone,
      transition: template_transition,
      navigate:   true, // not 100% sure this is needed
      source:     template_source
    }
    const render_opts = {...default_render_opts, ...event}
    up.navigate(render_opts)
  })
})


// nav-local=<string>
// nav-local value is used as part of the localstorage key

// expects attributes on children
// data-nav-local-default=<bool>
// data-nav-local-val=<string>
// nav-local-val is saved under the nav-local key
up.compiler ("[nav-local]", {priority:-100}, function (element){
  // nav_local_key should be unique per page
  const store              = sessionStorage ?? localStorage;
  const nav_local_key      = up.element.attr(element, "nav-local") ?? "default_nav_name"
  const store_key          = "nav_local:" + nav_local_key + ":" + up.history.location
  const nav_buttons        = up.util.toArray(element.querySelectorAll("[nav]"))
  const default_nav_button = nav_buttons.find(el =>  el.dataset.navLocalDefault === "true") ?? nav_buttons[0]
  const saved_tab_val      = store.getItem(djb2Hash(store_key))
  let current_active_nav   = nav_buttons.find(el =>  up.element.attr(el, "nav") === saved_tab_val) ?? default_nav_button

  // simulate user clicking on a tab (tab that we saved to localStorage)
  up.emit(current_active_nav, "up:click", {
    duration: 0,
  })

  // save the current tab to localStorage
  return up.on(nav_buttons, "up:click", function(event, element){
    const same_tab = (current_active_nav === element)
    const val = element.dataset.navLocalVal

    if(!same_tab && up.util.isGiven(val)){
      store.setItem(djb2Hash(store_key), val)
    }
  })
})

// set state of a nav/tab to active
up.compiler("[nav]", function(element, data){
  const listen_for = up.element.attr(element, "listen-for")
  const tab_name = element.dataset.tabName
  if(listen_for && tab_name){
    return up.on(listen_for, function(event){
      if(event.tab === tab_name){
        element.classList.add("active")
      }
      else {
        element.classList.remove("active")
      }
    })
  }
})

up.compiler("[active-on-click]", function(element, data){
  const nav = up.element.closest(element, "[nav]")
  console.log(nav)
  return up.on(element, "click", function(event){
    const sibilings = up.util.toArray(nav.querySelectorAll("[active-on-click]"))
    sibilings.forEach(el => el.classList.remove("active"))
    element.classList.add("active")
  })
})

up.compiler("[tab-group]", function(element){
  const nav_buttons        = up.util.toArray(element.querySelectorAll("[nav]"))
  const default_nav_button = nav_buttons.find(el =>  el.dataset.navDefault === "true") ?? nav_buttons[0]

  // simulate user clicking on a tab (tab that we saved to localStorage)
  up.emit(default_nav_button, "up:click", {
    duration: 0,
  })
})


// convenience macro for setting default attrs on a tab/nav
up.macro("tab", function(element, data){
  const target        = element.dataset.upTarget
  const listen_for    = up.element.attr(element,"listen-for")
  const up_emit       = up.element.attr(element,"up-emit")
  const up_emit_props = up.element.attr(element,"up-emit-props")
  const nav           = up.element.attr(element, "nav")
  const nav_local_val = element.dataset.navLocalVal
  const tab_name_attr = element.dataset.tabName
  const add_name      = nav && !tab_name_attr
  const tab_name      = tab_name_attr ?? nav
  if(!nav_local_val) {element.setAttribute("data-nav-local-val", nav)}
  if(add_name)       {element.setAttribute("data-tab-name",      tab_name)}
  if(!target)        {element.setAttribute("up-target",          "tab-body")}
  if(!listen_for)    {element.setAttribute("listen-for",         "tab:change")}
  if(!up_emit)       {element.setAttribute("up-emit",            "tab:change")}
  if(!up_emit_props) {element.setAttribute("up-emit-props",      `{"tab" : "${tab_name}"}`)}
})


// previous attempt at doing tabs
// DEPRECATED: use [up-template], [nav-local], or [tab] instead
up.compiler("tabs", function(element, data){
  const title_class    = element?.dataset?.titleClass ?? "item"
  const content_class  = element?.dataset?.contentClass ?? "tab"
  const selected_class = element?.dataset?.selectedClass ?? "active"

  //const tab_title_elements = element.
  const tab_title_query = "tab-title, [tab-title]"
  let tab_title_els = element.querySelectorAll(tab_title_query)

  if (up.util.isBlank(tab_title_els)){
    console.error(`There are no ${tab_title_query} tags in this tab group`, element)
    return
  }
  else {
    console.log("found tab titles", tab_title_els)
  }

  const active_tab_attribute = "active-tab"
  const active_tab_title = up.util.find(tab_title_els, e => {
    return up.element.booleanAttr(e, active_tab_attribute) || e.classList.contains(selected_class)
  }) ?? tab_title_els[0]

  if (! active_tab_title ){
    console.error("There are no tabs to set to active for tab group", element)
    return
  }

  let active_tab_index
  up.util.each(tab_title_els, (e,idx) => {
    if(e == active_tab_title){
      active_tab_index = idx;
    }
    e.classList.toggle(title_class, true)
    e.classList.toggle(selected_class, false)
  })

  const tab_content_tag = "tab-content"
  let tab_content_els = element.querySelectorAll(tab_content_tag)
  if (up.util.isBlank(tab_content_els)){
    console.error(`There are no ${tab_content_tag} tags in this tab group`, element)
    return
  }

  let active_tab_content = tab_content_els[active_tab_index]
  up.util.each(tab_content_els, e => {
    e.classList.toggle(content_class, true)
    up.element.hide(e)
  })

  active_tab_title.classList.toggle(selected_class, true)
  up.element.show(active_tab_content)

  const title_to_content_lookup = up.util.map(tab_title_els, (e, idx) => {
    return [e, tab_content_els[idx]];
  })

  cleanup_fns = title_to_content_lookup.map(([title, content]) => {
    return up.on(title, "click", function(event){
      up.util.each(tab_title_els, e => e.classList.toggle(selected_class, false))
      up.util.each(tab_content_els, up.element.hide)
      title.classList.toggle(selected_class, true)
      up.element.show(content)
    })
  });

  return function(){
    cleanup_fns.forEach(fn => fn());
  }
})
