/* global require, up, USA_CURRENCY_FORMAT_COMPACT, bb */

/*
  uses billboard.js to make a fancy edible chart

  example:
  https://naver.github.io/billboard.js/demo/#Chart.DonutChart
*/

up.compiler("donut-chart", function(element, data){

  const title = element.dataset?.titleLabel;
  const title_value = element.dataset?.titleValue ? USA_CURRENCY_FORMAT_COMPACT.format(element.dataset.titleValue) : "$0" ;

  const att_width = element.dataset?.width ?? "370";
  const att_height = element.dataset?.height ?? "240";
  const att_legend = element.dataset?.legend;

  //console.log("dataset",element.dataset)

  const width = parseInt(att_width)
  const height = parseInt(att_height)
  const legend = att_legend === "false" ? false : true

  const data_set_labels = data?.chart?.columns?.map(([x, ...rest]) => x);

  if (! data_set_labels){
    return;
  }

    //console.log(data)

    // we can set specific colors for data like so.
    //const default_colors = {Energy: "black"};

  const pattern00 = ["#c48e6e","#eed1be","#db7a6b","#eedfaa","#aac7da","#b8f5e8","#c1beda","#c0b3e5","#f88bb9","#d4eaae"];
  const pattern0 = ["#fb89bc","#b5179e","#9b15f4","#560bad","#480ca8","#3a0ca3","#3f37c9","#4361ee","#4895ef","#12b2e2"];
  const pattern9 = ["#fb89bc","#ea5dd5","#bc63f8","#a966f5","#a979f6","#9167f4","#655fd3","#5773ef","#4895ef","#12b2e2"];
  const pattern1 = ["#f72585","#b5179e","#7209b7","#560bad","#480ca8","#3a0ca3","#3f37c9","#4361ee","#4895ef","#4cc9f0"];
  const pattern2 = ["#C9E561","#b5e48c","#99d98c","#76c893","#52b69a","#34a0a4","#168aad","#1a759f","#1e6091","#184e77"];
  const pattern3 = ["#f94144","#f3722c","#f8961e","#f9844a","#f9c74f","#90be6d","#43aa8b","#4d908e","#577590","#277da1"];
  const pattern4 = ["#e2e2df","#d2d2cf","#e2cfc4","#f7d9c4","#faedcb","#c9e4de","#c6def1","#dbcdf0","#f2c6de","#f9c6c9"];
  const pattern5 = ["#9e6240","#dea47e","#cd4631","#f8f2dc","#81adc8","#48e5c2","#585191","#4f359b","#550527","#688e26"];

  // https://naver.github.io/billboard.js/release/latest/doc/Options.html#.pie
  let chart_options = {
    bindto: element,
    size: {
      height: height,
      width: width
    },
    // not seeing innerRadius outerRadius doing anything
    // expected that they would make the chart look fatter
    // innerRadius: 100,
    // outerRadius: 1000,
    data: {
      type : "donut",
      columns: data?.chart?.columns,
      order: null, // desc/asc didn"t seem to work (maybe it"s only considered the numbers, not labels)
      labels: {
        colors: "black"
      }
    },
    legend: {
      position: "right",
    },
    color: {
      pattern: pattern00
    },
  }

  if (legend) {
    chart_options.legend.show = true;
  }
  else {
    chart_options.legend.hide = true;
  }

  const chart_title = `${title} ${title_value}`;

  if (title){
    chart_options.donut ? chart_options.donut.title = chart_title : chart_options.donut = {title: chart_title};
  }

  let chart = bb.generate(chart_options);

  // FIXME: WTF is wrong with bb.js???
  // setting options causes exceptions in the destroy function
  // if (legend) {
  //   chart.legend.show();
  //   // shouldn"t need to list datasets according to BB API docs
  //   //chart.legend.show(data_set_labels);
  //   //chart_options.legend.show = true;
  // }
  // else {
  //   chart.legend.hide();
  //   //chart_options.legend.hide = true;
  // }

  return function(){
    //FIXME: this is throwing exceptions, but only with the addition of legend.show/hide, without those it doesn"t seem to throw
    //chart.destroy();
  }
})
