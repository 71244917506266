/* global require, up, Plyr */

up.compiler("[plyr]", function(element, data){
  const default_options = {
    debug: true,
    ratio: "16:9",
    storage: {enabled: true,
              key: 'plyr'}
  }
  const player = new Plyr(element, default_options);
  const supported = Plyr.supported('video', 'html5');

  if (!supported){
    error.log("plyr supported", supported)
  }

  return function(){
    plyr.destroy()
  }
})
