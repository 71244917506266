/* global require, up, Cleave, FilePond, DataTable, tail, bb */

// semantic ui tabs

// DEPRECATED
up.compiler(".pointing ", function(element, data){
  // console.log("new tabs", element)

  const tab_switchers = element.querySelectorAll(".item[data-tab]")
  const tab_switchers_lookup = up.util.toArray(tab_switchers).reduce((acc, el) => {
    acc[el?.dataset?.tab] = el
    return acc
  },{})

  let default_active_tab = up.element.get(element, ".item.active[data-tab]")
  let current_tab_name;

  if (default_active_tab) {
    current_tab_name = default_active_tab?.dataset?.tab
  }
  else {
    current_tab_name = up.util.toArray(tab_switchers).reduce((acc, el) => {
      if (acc) {
        return acc
      }
      else if (up.element.isVisible(el)){
        return el?.dataset?.tab
      }
      else {
        return null;
      }
    }, null)
  }

  // console.log(tab_switchers)
  // console.log(tab_switchers_lookup)

  const tabs = element.querySelectorAll(arent, ".tab[data-tab]")
  const tabs_lookup = up.util.toArray(tabs).reduce((acc, el) => {
    acc[el?.dataset?.tab] = el
    return acc
  },{})

  function switch_to_tab (change_to_tab_name) {
    if (change_to_tab_name){
      console.log ("switching to tab", change_to_tab_name)
      const change_tab_el      = tabs_lookup[change_to_tab_name]
      const change_switch_el   = tab_switchers_lookup[change_to_tab_name]

      const current_switch_el = tab_switchers_lookup[current_tab_name]
      const current_tab_name_el    = tabs_lookup[current_tab_name]

      if (current_switch_el && change_switch_el){
        current_switch_el.classList.toggle("active")
        current_tab_name_el.classList.toggle("active")

        change_switch_el.classList.toggle("active")
        change_tab_el.classList.toggle("active")

        current_tab_name = change_to_tab_name
      }
    }
  }

  //switch_to_tab (current_tab_name)

  const switch_handlers = up.util.toArray(tab_switchers).map(tab_switch_el => {
    //console.log("add click handler to ", tab_switch_el)
    return up.on(tab_switch_el, "click", event => {
      const change_to_tab_name = tab_switch_el?.dataset?.tab;
      switch_to_tab (change_to_tab_name)
    })
  })

  return switch_handlers
})
