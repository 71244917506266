/* global require, up, FilePond */


// https://pqina.nl/filepond/docs/api/instance/events/#filepond-loaded-event
// document.addEventListener("FilePond:loaded", (e) => {
//   console.log("FilePond ready for use", e.detail);

//   // get create method reference
//   const { create } = e.detail;
// });

// Initialize FilePond

//TODO: add properties to input field https://pqina.nl/filepond/docs/api/instance/properties/
up.compiler("input[filepond]", function(element, data){
  // look for sibling object tag that has json data for filepond

  const form = element.form;
  const target = up.element.attr(form, "up-target")
  const layer = up.element.attr(form, "up-layer") ?? "root"
  const server_url = element?.dataset?.server ?? "./"

  let pond;

  console.log("input[filepond]", target, layer, form)

  // use the form to get target and layer

  function onprocessfile(error, file_item) {
    // when the server is finished processing a file it will look like this
    const file = file_item.file

    if (error) {
      console.error('Error during file upload:', error);
      return;
    }

    if (file_item.origin === FilePond.FileOrigin.INPUT) {
      // console.log('New file uploaded:', file, file_item.serverId, file_item);

      // Trigger Unpoly to update part of the page
      // Assuming the server response includes a URL or content to update

      // can use a url here, maybe better than having the filepond
      // server code know how to render dataroom stuff

      up.util.task(function(){
        const render_options = {
          layer: up.layer[layer],
          target: target,
          url: `${server_url}?partial=true`,
          peel: false
        }

        // console.log("render options", render_options)
        up.render(render_options);  // Replace with the appropriate Unpoly call
      })
    }
  }

  function onremovefile(error, file_item) {
    if (error) {
      console.error('File removal error:', error);
      return;
    }

    // files here is null
    // console.log("pond files:", pond.files)

    // try beforeRemoveFile, which is not given an onbeforeremovefile function
    // file_item.source is fucked for when a file is uploaded and before filepond page is refreshed
    // console.log('File removed:', file_item.source, file_item.id, file_item.filename, file_item);

    up.util.task(function(){
      const render_options = {
        layer: up.layer[layer],
        target: target,
        url: `${server_url}?partial=true`,
        peel: false
      }

      // console.log("render options", render_options)
      up.render(render_options);  // Replace with the appropriate Unpoly call
    })
  }

  let files;
  try {
    files = JSON.parse(element.parentElement.querySelector("object").innerHTML)
  }
  catch {
    console.error("no sibling object tag with filepond json data as innerHTML found", element)
  }

  // Create a FilePond instance with files from the server
  if(FilePond.supported()){
    pond = FilePond.create(element)
    const options = {
      storeAsFile: false,
      server: {
        url: server_url
        // revert, at least, doesn"t send HTTP when uncommented....
        // load: (src, load) => {
        //   fetch(src)
        //     .then(res => res.blob())
        //     .then(load);
        // },
        // revert: (src, load) => {
        //   console.log("revert", src);
        //   load();
        // },
        // remove: (src, load) => {
        //   console.log("remove", src);
        //   load();
        // }
      },
      onprocessfile: onprocessfile,
      onremovefile: onremovefile
    }

    //httpas://pqina.nl/filepond/docs/api/instance/properties/#files
    //files: [],
    //storeAsFile: true,
    //allowMultiple: true

    if(files?.length){
      options.files = files
    }

    console.log("filepond options", options)
    pond.setOptions(options);

    return function(){
      FilePond.destroy(element)
    }
  }
})
