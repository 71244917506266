/* global require, up */

// generate strong passwords because we can't trust users to do so
up.compiler("button[easy-password]", function (element, data){
  const password_selector = up.element.attr(element, "password-selector");
  const sibilings = element.parentElement
  const password_field =  sibilings.querySelector(password_selector) || element.closest(password_selector) || document.querySelector (password_selector)

  if (password_field){
    const password_length = up.element.numberAttr(password_field, "minLength") ?? 100

    function generatePassword(length) {
      var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
      var password = "";
      for (var i = 0; i < length; i++) {
        password += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      return password;
    }

    return up.on (element,"click", (event) => {
      event.preventDefault();
      const new_password = generatePassword(password_length)
      password_field.value = new_password
    })
  }
})
