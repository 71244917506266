/* global require, up */

up.compiler(".ui.checkbox", {batch: true}, function(elements, data){
  elements.forEach(element => {
    const input = element.querySelector("input")

    const cleanup = up.on(element, "click", function(e){
      console.log("checked toggle", element, input, e)
      element.classList.toggle("checked")
      input.toggleAttribute("checked")
    })

    up.destructor(element, cleanup)
  })
})

up.compiler("checkbox-group", function(element, data){
  const cleanups = [
    up.on(element, "checkboxes:select-all", function(e){
      //console.log("event", e)
      const checkboxes = element.querySelectorAll("[type=checkbox]")
      checkboxes.forEach(checkbox => {
        checkbox.checked = true
      })
    }),

    up.on(element, "checkboxes:select-none", function(e){
      //console.log("event", e)
      const checkboxes = element.querySelectorAll("[type=checkbox]")
      checkboxes.forEach(checkbox => {
        checkbox.checked = false
      })
    }),

    up.on(element, "checkboxes:invert", function(e){
      //console.log("event", e)
      const checkboxes = element.querySelectorAll("[type=checkbox]")
      checkboxes.forEach(checkbox => {
        checkbox.checked = !checkbox.checked
      })
    })
  ]

  return function() {
    cleanups.forEach((cleanup_fn) => cleanup_fn())
  }
})
