/* global require, up, Prism, prettier, prettierPlugins */

up.compiler("[pretty-json]", async function(element, data) {
  const code_text = element.innerText

  const prettier_options = {
    parser: "json5",
    plugins: prettierPlugins,
    arrowParens: "always",
    bracketSameLine: false,
    bracketSpacing: true,
    semi: true,
    experimentalTernaries: false,
    singleQuote: false,
    jsxSingleQuote: false,
    quoteProps: "as-needed",
    trailingComma: "all",
    singleAttributePerLine: false,
    htmlWhitespaceSensitivity: "css",
    vueIndentScriptAndStyle: false,
    proseWrap: "preserve",
    insertPragma: false,
    printWidth: 80,
    requirePragma: false,
    tabWidth: 2,
    useTabs: false,
    embeddedLanguageFormatting: "auto"
  }

  if (code_text) {
    try {
      const formatted = await prettier.format(code_text, prettier_options);
      element.textContent = formatted;
      Prism.highlightElement(element);
    } catch (error) {
      console.error('Formatting error:', error)
      element.textContent = `Error: ${error.message}`;
      element.classList.add('language-plaintext')
      Prism.highlightElement(element);
    }
  }
})


if (typeof Prism !== 'undefined') {

  // add titles to string attributes because sometimes they'll be ellipsised
  Prism.hooks.add("wrap", function(env) {
    if (env.type !== "string") {
      return;
    }

    env.attributes.title = env.content
  });

  function cleanupQuotes(str) {
    if (str.startsWith('"') && str.endsWith('"')) {
      return str.slice(1, -1);
    }
    return str
  };

  // make namespaced fields look nice
  Prism.hooks.add("wrap", function(env) {
    if (env.type !== "property") {
      return;
    }

    env.content = cleanupQuotes(env.content)
  });
}
